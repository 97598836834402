





















import { Component, Vue, Mixins } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import VictoryBranch from '@/mixins/v3/VictoryBranch'

@Component({
  components: {
    ButtonBase,
  },
})
export default class VictoryPrintPage extends Mixins(VictoryBranch) {
  private async handleBackPage() {
    Vue.prototype.$loading.start()
    const { subjectId, subjectName, subjectCode } = this.$route.query
    this.$router.push(`/student/v3/victory/units/${subjectCode}?subjectId=${subjectId}&subjectName=${subjectName}`)
    Vue.prototype.$loading.complete()
  }

  private unitName = this.$route.query.unitName
  private printObjects = {}

  get printTypes() {
    return {
      hmks: '問題・ヒント・解答・解説',
      hks: 'ヒント・解答・解説',
      m: '問題',
      k: '解答',
      s: '解説',
      h: 'ヒント',
    }
  }

  private isPrintTypeExist(printType: string) {
    return Object.keys(this.printObjects).find((sortNum) => {
      return this.printObjects[sortNum][printType]
    })
  }

  private async mounted() {
    if (!this.isVictoryBranch) {
      this.$router.push('/student/v3/dashboard')
    } else {
      Vue.prototype.$loading.start()
      await this.handleGetPrintList()
      Vue.prototype.$loading.complete()
    }
  }

  private async handleViewPdf({ id, url }: { id: number; url: string }) {
    if (!id || !url) return
    try {
      await Vue.prototype.$http.httpWithToken.post('/v3/result_victory_view_prints', {
        victory_print_path_id: id,
      })
      window.open(url, '_blank')
    } catch (error) {
      alert('学習するプリントがありません')
    }
  }

  private async handleGetPrintList() {
    const { unitId } = this.$route.params
    const result = await Vue.prototype.$http.httpWithToken.get('/v3/victory_print_paths', {
      params: { victory_unit_id: unitId },
    })
    this.convertPrintList(result.data.victory_print_paths)
  }

  private convertPrintList(printResponse: any) {
    this.printObjects = printResponse.reduce((accumulator: any, { sort_num, file_type, ...printRest }: any) => {
      if (accumulator[sort_num]) {
        accumulator[sort_num][file_type] = printRest
      } else {
        accumulator[sort_num] = {}
        accumulator[sort_num][file_type] = printRest
      }
      return accumulator
    }, {})
  }
}
